<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>お知らせの編集</template>
              <template v-slot:body>
                <TopicForm ref="form" :isEdit="true" />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" @click="showModal('deleteModal')">削除する</button>
          </li>
          <li class="listGrid-item">
            <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ !paused ? '非公開' : '公開' }}</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: listRoute }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="edit" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deleteModal')" v-if="deleteModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">選択したお知らせを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deleteModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="handleDeleteTopic" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ paused ? '公開' : '非公開' }}</template>
      <template v-if="paused" v-slot:body>
        <p class="description text-align-center">このお知らせを公開しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatus" button-text="公開する" />
          </li>
        </ul>
      </template>
      <template v-else v-slot:body>
        <p class="description text-align-center">このお知らせを非公開にしますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatus" button-text="非公開にする" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/news';
import modal from '@/mixins/plugin/modal';
import TopicForm from './Form.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Topic',
  data: function() {
    return {
      pageName: 'お知らせ',
      deleteModal: false,
      pauseModal: false,
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      newsDetail: 'news/newsDetail',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
    }),
    pageNavs() {
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) {
        return this.storePageNavs;
      } else {
        return this.officePageNavs;
      }
    },
    newsId() {
      return this.$route.params.id;
    },
    subdomain() {
      if (this.$permission.isStoreOrGroup()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    listRoute() {
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) {
        return 'ShopNews';
      } else {
        return 'News';
      }
    },
    paused() {
      return !this.newsDetail.validFlag;
    },
  },
  components: {
    TopicForm,
    Modal,
  },
  mixins: [nav, modal],
  methods: {
    async edit() {
      const payload = await this.$refs.form.validate();
      if (payload) {
        const result = await this.$store.dispatch('news/updateNews', {
          ...payload,
          id: this.newsId,
          subdomain: this.subdomain,
        });
        if (result) {
          await this.$router.push({ name: this.listRoute });
          this.$message.updated('topic');
        }
      }
    },
    async handleDeleteTopic() {
      const result = await this.$store.dispatch('news/deleteNews', {
        id: this.newsId,
        subdomain: this.subdomain,
      });
      if (result) {
        await this.$router.push({ name: this.listRoute });
        this.$message.deleted('topic');
      }
    },
    async changeStatus() {
      const result = await this.$store.dispatch('news/updateNewsStatus', {
        subdomain: this.subdomain,
        id: this.newsId,
        status: this.paused,
      })
      this.closeModal('pauseModal');
      if (result) {
        this.$message.updated('status');
      }
    },
  },
};
</script>
